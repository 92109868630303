#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  font-size: 14px;
  color: #333;
  margin-top: auto;
  padding: 50px;
  /* background-color: #aed893; */
  text-align: center;
  border-top: 1px solid #eee;
}

footer span {
  display: inline-block;
  margin: 0 10px;
}

footer a {
  color: #000;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}