$dark: #08947c;
$light: #e6f7f4;
$border: #cce5e1;
$hover: #f0faf8;

* {
  box-sizing: border-box;
}

.page {
  max-width: 1280px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

h1 {
  color: $dark;
}

.memoryList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.memoryCard {
  background-color: white;
  border: 1px solid $border;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
  
  &:hover {
    background-color: $hover;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .memoryType {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 12px;
    background-color: $light;
    color: $dark;
    text-transform: uppercase;
  }
  
  .memoryName {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
    padding-right: 60px;
  }
  
  .memoryContent {
    color: #555;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .memoryDate {
    margin-top: 8px;
    font-size: 12px;
    color: $dark;
    font-style: italic;
  }
}

.editForm {
  margin-top: 10px;
  
  input, textarea, select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid $border;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    
    &:focus {
      outline: none;
      border-color: $dark;
    }
  }
  
  textarea {
    min-height: 80px;
    resize: vertical;
  }
  
  .buttons {
    display: flex;
    gap: 10px;
    
    button {
      padding: 6px 12px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-size: 14px;
      
      &.saveBtn {
        background-color: $dark;
        color: white;
      }
      
      &.cancelBtn {
        background-color: #f1f1f1;
        color: #333;
      }
    }
  }
}

.memoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  
  h2 {
    margin: 0;
  }
}

.addButton {
  background-color: $dark;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: darken($dark, 10%);
  }
}

.newMemoryForm {
  background-color: white;
  border: 1px solid $border;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  
  .newMemoryTextarea {
    width: 100%;
    min-height: 120px;
    padding: 12px;
    border: 1px solid $border;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 16px;
    resize: vertical;
    box-sizing: border-box;
    
    &:focus {
      outline: none;
      border-color: $dark;
    }
  }
  
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    
    button {
      padding: 8px 16px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-size: 14px;
      
      &.saveBtn {
        background-color: $dark;
        color: white;
      }
      
      &.cancelBtn {
        background-color: #f1f1f1;
        color: #333;
      }
      
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}
