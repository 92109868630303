.test-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.test-page h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.test-prompt {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin: 10px 0;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.4;
  overflow-x: auto;
}

.run-tests-button {
  display: block;
  margin: 20px auto;
  padding: 12px 24px;
  font-size: 18px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.run-tests-button:hover {
  background-color: #45a049;
}

.test-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.test-section h2 {
  color: #444;
  margin-bottom: 15px;
  font-size: 1.4em;
}

.test-section input[type="text"],
.test-section input[type="email"] {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.test-section button {
  padding: 8px 16px;
  margin: 5px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.test-section button:hover {
  background-color: #1976D2;
}

.test-section a {
  display: inline-block;
  margin: 10px 0;
  color: #2196F3;
  text-decoration: none;
}

.test-section a:hover {
  text-decoration: underline;
}

.click-area {
  padding: 20px;
  background-color: #e3f2fd;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  margin: 10px 0;
}

#hold-button {
  background-color: #ff9800;
}

#hold-button:hover {
  background-color: #f57c00;
}

.vision-test-area {
  text-align: center;
}

.vision-test-area img {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
  border-radius: 4px;
}

#test-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#test-form button[type="submit"] {
  background-color: #673ab7;
}

#test-form button[type="submit"]:hover {
  background-color: #5e35b1;
}

.validate-button {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 18px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px 0;
}

.validate-button:hover {
  background-color: #1976D2;
}

.test-results {
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
}

.test-results.passed {
  background-color: #e8f5e9;
  border: 1px solid #4caf50;
}

.test-results.failed {
  background-color: #ffebee;
  border: 1px solid #f44336;
}

.test-results h3 {
  margin: 0 0 15px 0;
  color: #333;
}

.failure-list {
  margin: 0;
  padding-left: 20px;
  color: #d32f2f;
}

.failure-list li {
  margin: 5px 0;
} 